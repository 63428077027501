import React, { useEffect, useMemo, useRef, useState } from "react";
import { ButtonSave } from "../Buttons/ButtonSave";
import { SafeSearch } from "./SafeSearch";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { injectIntl } from "react-intl";

import { v4 as uuid } from "uuid";
import { CustomList } from "../List/List";

/**@typedef {API.PortalObject['safeSearches'][number] & { uuid: string; }} SafeSearch */

const SafeSearchItem = (
    /**@type {import("../List/List").CustomCommonProps<SafeSearch, SafeSearch>}>}*/ {
        uuid,
        item,
        updateItem,
        removeItem,
    },
) => {
    /**
     * @function onInput
     * @param {React.ChangeEvent<HTMLInputElement>} e
     */
    const onInput = (e) => {
        const { name, value } = e.target;

        updateItem && updateItem(uuid, { ...item, [name]: value });
    };

    return (
        <tr>
            <td>
                <input
                    className="form-input"
                    type="text"
                    name="domain"
                    value={item.domain}
                    onInput={onInput}
                />
            </td>
            <td>
                <input
                    className="form-input"
                    type="text"
                    name="cname"
                    value={item.cname}
                    onInput={onInput}
                />
            </td>
            <td>
                <button
                    type="button"
                    className="btn btn-error btn-sm"
                    style={{
                        padding: "0.2rem .6rem",
                        height: "1.8rem",
                    }}
                    onClick={() => removeItem(uuid)}
                >
                    <i className="icon icon-delete"></i>
                </button>
            </td>
        </tr>
    );
};

/**
 * @function Edit
 * @param {{ portal: API.PortalObject; updatePortal(id: string, portal: API.PortalObject): Promise<void>; setIsEditing(value: boolean): void } & ReactIntl.InjectedIntlProps} props
 */
export const Edit = ({
    intl: { formatMessage },
    updatePortal,
    setIsEditing,
    ...props
}) => {
    /**@type {ReactExt.State<SafeSearch[]>}*/
    const [safeSearches, setSafeSearches] = useState(
        props.portal.safeSearches.map((s) => ({ ...s, uuid: uuid() })),
    );

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            name: props.portal.name,
            domains: props.portal.domains.split(",").join("\n"),
            text: props.portal.text,
            age: props.portal.age,
        },
    });

    /**@type {Parameters<typeof handleSubmit>[0]} */
    // @ts-ignore
    const onValid = (data) => {
        updatePortal(props.portal.id, {
            ...props.portal,
            ...data,
            domains: data.domains.split("\n").join(","),
            safeSearches,
        }).then(() => setIsEditing(false));
    };

    /**@type {Parameters<typeof handleSubmit>[1]} */
    // @ts-ignore
    const onInvalid = (errors) => {
        // TODO: Show alert
    };

    const domainsRef = useRef(null);
    const domainsProps = register("domains");

    useEffect(() => {
        const textarea = domainsRef?.current;
        if (!textarea) return;

        textarea.style.height = textarea.scrollHeight + "px";
        const handler = () => {
            textarea.style.height = "auto";

            const lineHeight = getComputedStyle(textarea)["line-height"] ?? 24;
            const height = textarea.scrollHeight;

            const maxLines = 10;

            const finalHeight = Math.min(
                maxLines * parseFloat(lineHeight),
                height,
            );

            textarea.style.height = `${finalHeight}px`;
        };

        textarea.addEventListener("input", handler);
        return () => textarea.removeEventListener("input", handler);
    }, [domainsRef]);

    return (
        <>
            <form
                className="form-horizontal"
                onSubmit={handleSubmit(onValid, onInvalid)}
            >
                <div className="form-group">
                    <div className="col-3 col-sm-12">
                        <label className="form-label" htmlFor="name">
                            Name
                        </label>
                    </div>
                    <div className="col-9 col-sm-12">
                        <input
                            className={`form-input ${errors.name ? "is-error" : "is-success"}`}
                            type="text"
                            id="name"
                            placeholder="Name"
                            {...register("name", {
                                validate: (value) =>
                                    z.string().nonempty().safeParse(value)
                                        .success,
                            })}
                        />
                        {errors.name && (
                            <p className="form-input-hint">Required field.</p>
                        )}
                    </div>
                </div>

                <div className="form-group">
                    <div className="col-3 col-sm-12">
                        <label className="form-label" htmlFor="age">
                            Age
                        </label>
                    </div>
                    <div className="col-9 col-sm-12">
                        <select
                            className={`form-select ${errors.age ? "is-error" : "is-success"}`}
                            name="age"
                            id="age"
                            {...register("age")}
                        >
                            <option value="0">
                                {formatMessage({
                                    id: "children.ages.0",
                                })}
                            </option>
                            <option value="6">
                                {formatMessage({
                                    id: "children.ages.6",
                                })}
                            </option>
                            <option value="12" defaultChecked>
                                {formatMessage({
                                    id: "children.ages.12",
                                })}
                            </option>
                            <option value="16">
                                {formatMessage({
                                    id: "children.ages.16",
                                })}
                            </option>
                            <option value="18">
                                {formatMessage({
                                    id: "children.ages.18",
                                })}
                            </option>
                        </select>
                    </div>
                </div>

                <div className="form-group">
                    <div className="col-3 col-sm-12">
                        <label className="form-label" htmlFor="domains">
                            Domains
                        </label>
                    </div>
                    <div className="col-9 col-sm-12">
                        <textarea
                            className={`form-input ${errors.domains ? "is-error" : "is-success"}`}
                            id="domains"
                            placeholder="Text"
                            style={{
                                minHeight: "0",
                                maxHeight: "none",
                                height: "auto",
                            }}
                            {...domainsProps}
                            ref={(ref) => {
                                domainsRef.current = ref;
                                domainsProps.ref(ref);
                            }}
                        />
                    </div>
                </div>

                <div className="form-group">
                    <div className="col-3 col-sm-12">
                        <label className="form-label" htmlFor="text">
                            Text
                        </label>
                    </div>
                    <div className="col-9 col-sm-12">
                        <input
                            className={`form-input ${errors.name ? "is-error" : "is-success"}`}
                            type="text"
                            id="text"
                            placeholder="Text"
                            {...register("text")}
                        />
                    </div>
                </div>

                <div className="form-group">
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "center",
                            marginTop: "1rem",
                        }}
                    >
                        <hr
                            style={{
                                width: "100%",
                            }}
                        />
                        <h4>SafeSearches</h4>
                    </div>
                    <table className="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th>DOMAIN</th>
                                <th>CNAME</th>
                                <th>actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <CustomList
                                items={safeSearches}
                                accessor={(s) => s}
                                updateItem={(uuid, item) => {
                                    setSafeSearches((old) =>
                                        old.map((safeSearch) =>
                                            safeSearch.uuid === uuid
                                                ? item
                                                : safeSearch,
                                        ),
                                    );
                                }}
                                removeItem={(uuid) =>
                                    setSafeSearches((old) =>
                                        old.filter(
                                            (safeSearch) =>
                                                safeSearch.uuid !== uuid,
                                        ),
                                    )
                                }
                                component={SafeSearchItem}
                            />
                            <tr>
                                <td colSpan={3}>
                                    <button
                                        type="button"
                                        className="btn"
                                        style={{ width: "100%" }}
                                        onClick={() =>
                                            setSafeSearches((old) => [
                                                ...old,
                                                {
                                                    uuid: uuid(),
                                                    cname: "",
                                                    domain: "",
                                                },
                                            ])
                                        }
                                    >
                                        Add
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "0.5rem",
                        marginTop: "1rem",
                    }}
                >
                    <button className="btn btn-primary" type="submit">
                        <i
                            className="icon icon-check"
                            style={{ marginRight: "0.4rem" }}
                        />
                        Submit
                    </button>
                    <button
                        className="btn"
                        type="button"
                        onClick={() => setIsEditing(false)}
                    >
                        <i
                            className="icon icon-cross"
                            style={{ marginRight: "0.4rem" }}
                        />
                        Cancel
                    </button>
                </div>
            </form>
        </>
    );
};

export const EditPortal = injectIntl(Edit);
