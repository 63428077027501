import React, { useMemo, useState } from "react";
import { EditPortal } from "./EditPortal";
import { injectIntl } from "react-intl";

/**
 * @function ItemIntl
 * @param {import("react-intl").InjectedIntlProps & Record<string, any>} props
 */
export const ItemIntl = (props) => {
    const { formatMessage } = props.intl;

    const item = useMemo(() => props.item, [props.item]);
    const [isEditing, setIsEditing] = useState(false);

    return (
        <>
            {isEditing ? (
                <tr>
                    <td colSpan={6}>
                        <EditPortal
                            portal={item}
                            updatePortal={props.updateItem}
                            setIsEditing={setIsEditing}
                        />
                    </td>
                </tr>
            ) : (
                <tr>
                    <td>{item.name}</td>
                    <td>{item.domains}</td>
                    <td>{item.safeSearches.length}</td>
                    <td>{item.age}</td>
                    <td>{item.text}</td>
                    <td>
                        <button
                            className="btn btn-default btn-sm mr-2"
                            title={formatMessage({ id: "edit" })}
                            onClick={() => setIsEditing(true)}
                        >
                            <i className="icon icon-edit" />
                        </button>
                        <button
                            className="btn btn-error btn-sm"
                            title={formatMessage({ id: "delete" })}
                            onClick={() =>
                                window.confirm(
                                    formatMessage({
                                        id: "are-you-sure",
                                    }),
                                ) && props.deleteItem(item.id)
                            }
                        >
                            <i className="icon icon-delete" />
                        </button>
                    </td>
                </tr>
            )}
        </>
    );
};

export const Item = injectIntl(ItemIntl);
