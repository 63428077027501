import React from "react";

import { Item } from "./Item";

const PortalTable = (props) => (
    <>
        <table className="table table-striped table-hover">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Domains</th>
                    <th>Safe</th>
                    <th>Alter</th>
                    <th>Beschreibung</th>
                    <th>actions</th>
                </tr>
            </thead>
            <tbody>
                {props?.items?.map((item) => (
                    <Item
                        item={item}
                        key={item.id}
                        updateItem={props.updateItem}
                        deleteItem={props.deleteItem}
                    />
                ))}
            </tbody>
        </table>
    </>
);

export { PortalTable };
